// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withMappedReactProps } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Single, * as SingleInfo from "https://framerusercontent.com/modules/cBF15lBi7RiyI8q4CFpd/VDKXw1bdoJ5vGWb1hZF8/Zbmf7W7C5.js";
import { withSingleToggle } from "../codeFile/SingleToggle.tsx";
const SingleFonts = getFonts(Single);
const SingleWithSingleToggleWithMappedReactPropsg424ez = withMappedReactProps(withSingleToggle(Single), SingleInfo);

const cycleOrder = ["VGVRsnuSt"];

const serializationHash = "framer-xPxr8"

const variantClassNames = {VGVRsnuSt: "framer-v-rkkwyo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, iconColor, id, width, ...props}) => { return {...props, Tib6Q7ZnQ: iconColor ?? props.Tib6Q7ZnQ ?? "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Tib6Q7ZnQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "VGVRsnuSt", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rkkwyo", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VGVRsnuSt"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-qwf5qh-container"} layoutDependency={layoutDependency} layoutId={"zA6MfhtTL-container"}><SingleWithSingleToggleWithMappedReactPropsg424ez AeO5PLd9q={Tib6Q7ZnQ} ddWa08mZD={"fTmWKR9rn"} height={"100%"} id={"zA6MfhtTL"} layoutId={"zA6MfhtTL"} QNUIABbS6={"bold"} style={{height: "100%", width: "100%"}} variant={"udnmimFYL"} WfbOFlO0c={"Moon"} width={"100%"} zcws0PLsu={"Sun"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xPxr8.framer-2evqdq, .framer-xPxr8 .framer-2evqdq { display: block; }", ".framer-xPxr8.framer-rkkwyo { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-xPxr8 .framer-qwf5qh-container { flex: none; height: 40px; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xPxr8.framer-rkkwyo { gap: 0px; } .framer-xPxr8.framer-rkkwyo > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-xPxr8.framer-rkkwyo > :first-child { margin-left: 0px; } .framer-xPxr8.framer-rkkwyo > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Tib6Q7ZnQ":"iconColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermwEKtzQx7: React.ComponentType<Props> = withCSS(Component, css, "framer-xPxr8") as typeof Component;
export default FramermwEKtzQx7;

FramermwEKtzQx7.displayName = "SingleToggle";

FramermwEKtzQx7.defaultProps = {height: 40, width: 40};

addPropertyControls(FramermwEKtzQx7, {Tib6Q7ZnQ: {defaultValue: "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28)) /* {\"name\":\"font/active\"} */", title: "IconColor", type: ControlType.Color}} as any)

addFonts(FramermwEKtzQx7, [{explicitInter: true, fonts: []}, ...SingleFonts], {supportsExplicitInterCodegen: true})